$myHeaderColor: rgb(248 248 248);

.rdt_TableCol {
  border: 1px solid #DEE2E6;
  color: black;
  font-size: medium;
  background-color: $myHeaderColor;
  text-align: left;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.searchInput {
  flex: auto;
  align-items: start;
  text-align: left;
  input {
    width: 400px;
    padding: 0px 7px;
    font-size: 15px;
    border: 1px solid rgb(211, 211, 211);
    outline: none;
    text-align: left;
    border-radius: 4px;
    height: 40px;
  }
}

.rdt_TableRow {
  &:hover {
    cursor: pointer;
    background-color: #f8f4f4;
  }
}

.rdt_TableCell {
  border: 1px solid #DEE2E6;
  border-collapse: collapse;
  text-align: left;
}

.tableContainer header {
  padding: 4px 16px 4px 0px !important;
}


.rdt_TableCol_Sortable {
  display: flex;
  div {
    width: 100%;
  }
}

// PAGINATION

.pagination {
  margin-top: 5px;
  text-align: right;
  float: right;
  button {
    min-width: 32px;
    height: 32px;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.23)
  }

  li {
    margin-left: 3px;
    margin-right: 3px;
    color: rgba(0, 0, 0, 0.87);
  }

  .active {
    color: #ffffff;
    background-color: #F11286;
    button {
      color: #ffffff;
    }
  }
}
