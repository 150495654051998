$myHeaderColor: #61dafb;

.login-top-row {
  --bs-gutter-x: 0 !important;
}

// .left-container {
//   padding: 30px !important;
//   height: 100vh;
//   background-color: #518fb8;
//   background-size: contain, contain;
//   background-repeat: no-repeat, no-repeat;
//   background-position: right top, left bottom;
//   display: grid;
//   position: relative;
// }

.automated-solutions-container {
  //margin-top: 40%;
  font-size: 14px;
  position: absolute;
  max-width: 100%;
  bottom: 150px;
  text-align: left;
  color: #ffffff;
  padding: 30px;

  span {
    position: absolute;
    width: 61px;
    height: 6px;

    background: #78a22f;
    border-radius: 50px;
    margin-bottom: 10px;
  }

  p {
    text-align: left;
  }
}

.automated-solutions-footer {
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  bottom: 40px;
  padding: 0px 30px;
  width: 100%;
}

.login-section {
  // display: flex;
  // justify-content: center;
  align-items: center;
  padding-top: 15%;
}

.login-div {
  max-width: 450px;
  //background: rgba(252, 252, 252, 0.15);
  //border: 1px solid #f8f8f8;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding: 30px;
  text-align: left;
  margin: 0 auto;

  .separator {
    position: absolute;
    width: 61px;
    height: 2px;
    margin-left: -1%;
    background: #78a22f;
    border-radius: 50px;
  }

  h3 {
    color: #F11286;
  }

  label {
    color: #F11286 !important;
  }

  .text-span {
    color: #ffffff;
    display: block;
    margin: 20px 0px 10px;
    font-size: 14px;
  }

  input {
    background-color: transparent;
    color: white;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    outline: 0 !important;
    border-color: inherit;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .input-div {
    box-sizing: border-box;

    // position: absolute;
    // width: 417px;
    // height: 56px;
    // left: 1014px;
    // top: 465px;

    border: 1px solid #dee2e6;
    border-radius: 5px;
  }

  .input-checkbox {
    input {
      box-sizing: border-box;
      border: 1px solid #dee2e6;
      border-radius: 5px;
    }
  }

  .input-label {
    // position: absolute;
    // width: 64px;
    // height: 19px;
    // left: 1025px;
    // top: 468px;
    padding-left: 10px;

    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 0px;
    /* identical to box height */

    color: #559bc9;
  }

  .no-outline:focus {
    outline: none;
  }

  .sign-in-btn {
    width: 100%;
    height: 40px;
    background: #ffa800;
    border-color: #ffa800;
    border-radius: 5px;

    &:hover {
      background: #ffa800;
    }
  }
}

.login-container {
  height: 100vh;
  width: 100%;

  .login-bg {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: right;
  }

  .login-logo {
    height: 80px;
    width: auto;
    position: absolute;
    left: 3.28%;
    right: 85.94%;
    top: 3.8%;
    bottom: 89.81%;
    margin: 0;
  }

  .login-logo-center {
    position: absolute;
    width: 615px;
    height: 189px;
    left: 63px;
    top: 220px;
  }

  .login-footer {
    display: flex;

    .line {
      position: absolute;
      left: -0.10%;
      right: 93.7%;
      top: 93.7%;
      bottom: 4.44%;
      background: #F11286;
      border-radius: 5px;
    }

    .links {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 132px;
      top: 93.7%;
      bottom: 4.44%;
      width: 50vh;
      font-family: Futura Std;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }

  .login-image-top {
    text-align: end;
    img {
      max-width: 480px;
    }
  }
  .login-image-logo {
    position: absolute;
    left: 81.61%;
    right: 13.23%;
    top: 26.02%;
    bottom: 64.26%;
  }

  .login-title {
    font-family: Futura Std;
    font-style: normal;
    font-weight: 650;
    font-size: 32px;
    line-height: 38px;
    color: #444;
  }
  
  .login-form {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    width: 100%;
    
    .line{
      width: 125px;
      height: 6px;
      background: #F11286;
      border-radius: 50px;
    }
    .sub-title {
      font-family: Futura Std;
      font-style: normal;
      font-weight: 650;
      font-size: 24px;
      line-height: 29px;
      color: #F11286;
    }
    .description {
      font-family: Futura Std;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #7E7E7E;
      width: 69%;
    }
  }

}