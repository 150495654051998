.color-picker-input {
  .swatches-picker {
    position: absolute;
    background-color: white;
    z-index: 99999999;
    width: 100% !important;
  }
}

.signature-buttons-span {
  .dropdown-toggle::after {
    border-top: 0;
  }
}
