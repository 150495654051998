// Variable overrides
// Here you can add other styles

$theme-colors: (
  'primary': #4638c2,
  'secondary': #4c4f54,
  'success': #45a164,
  'info': #4799eb,
  'warning': #e1a82d,
  'danger': #d16767,
  'light': #6c6e7e,
  'dark': #0e0e15,
  // "color": #e1e1e1,
);
$body-bg: #ffffffde;
// $body-color: #ffffffde;
$body-color: #000000de;
$elevation-base-color: 0, 0, 0;
.c-dark-theme {
  --elevation-base-color: 0, 0, 0;
}