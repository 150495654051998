.custom-navbar {
    background-color:#353535;
    padding: 0 !important;
    .nav-link {
      color: #fff;
      font-size: 14px;
      padding: 12px 0px;
    }
    .nav-link:hover {
      color: #fff !important;
    }
    .dropdown-item a {
      color: #000 !important;
      text-decoration: none;
    }
    .dropdown-menu.show {
      padding: 0px;
      border-radius: 0px;
      .dropdown-item {
        font-size: 14px;
      }
    }

    .dropdown-item.active, .dropdown-item:active {
        color: #fff;
        text-decoration: none;
        background-color: #F11286;
    }
  }
  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #fff !important;
  }
  .nav-link:hover, .nav-link:focus {
    color: #fff !important;
  }
  .user-avtar {
    height: 30px;
      width: 30px;
      display: block;
      background: #ccc;
      border-radius: 15px;
  }