.wrapperSearchIcon {
  width: 100%;
  max-width: 31.25rem;
  margin: 50px auto;
}
.labelSearchIcon {
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: +1.3px;
  margin-bottom: 1rem;
}
.searchBarSearchIcon {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#searchQueryInputSearchIcon {
  width: 100%;
  height: 50px;
  background: #ffffff;
  outline: none;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
}
#searchQuerySubmitSearchIcon {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.cardSearchIcon {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  // margin: 10px;
  // width: 10%;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}
.containerSearchIcon {
  padding: 2px 16px;
}

.centerSearchIcon {
  text-align: center;
}

.flex-container-si {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  div {
    padding: 0mm;
    margin: 5px 5px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.searchIconInputDiv {
  border: 1px solid lightgray;
  padding-left: 7px;
  padding-bottom: 7px;
  border-radius: 8px;
  .label {
    float: left;
    font-size: 10px;
    left: 7.5px;
    margin: 0px;
    padding: 1;
    margin-bottom: 5px;
    top: 2px;
    font-weight: bold;
    user-select: none;
  }
  .iconPDiv {
    width: 100%;
    display: flex;
    .iconName {
    }
    .searchIcon {
      text-align: end;
    }
    .filler {
      flex-grow: 0.94;
    }
  }
  .iconAndName {
    // display: flex;
    gap: 5px;
    padding-bottom: 5px;
  }
  .colorAndName {
    display: flex;
    gap: 5px;
  }
}
.colorPickerDiv {
  display: flex;
  gap: 35px;
  justify-content: center;
}
.iconModalComponent {
  // width: 1100px;
  height: 500px;
}
label[for="File"] {
  max-width: 100%;
  height: 100px;
}
.circleColorDiv {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.colorPickerSelect {
  margin-top: 50px;
  display: flex;
  gap: 35px;
  justify-content: center;
}

// NOTES
.Notes-notes-area {
  resize: none;
  width: 100%;
  padding: 18px 45px 15px 12px;
  outline: none;
  height: 120px;
  .textarea {
    overflow: auto;
    resize: vertical;
  }
}

.input-field-root {
  border: 1px solid #ccc;
  font-size: 1rem;
  flex-wrap: inherit !important;
  display: block !important;
}

.Notes-send-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: none;
  outline: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-left: 1px solid #d6d6d6;
  padding: 0 0 0 0.25rem;
}
.ReactModal__Overlay {
  z-index: 99999;
}

.samrtjkcradiobutton,
.samrtjkcCheckboxbutton {
  padding-right: 10px;
  input {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
    transform: scale(1.5);
    margin-left: 6px;
  }
}
.samrtjkcradiobutton input[type="radio"] {
	appearance: none;
	width: 18px !important;
	height: 18px !important;
	border-radius: 50%;
  border: 1px solid rgb(211, 211, 211);
	background-clip: content-box;
	padding: 3px;
  margin-right: 5px !important;
}
.samrtjkcradiobutton input[type="radio"]:checked {
	background-color: rgb(241, 18, 134);
  border: 2px solid rgb(241, 18, 134);
}

.fontSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  overflow: hidden !important;
  scrollbar-width: none !important; /*For Firefox*/
  -ms-overflow-style: none !important; /*For Internet Explorer 10+*/
  option {
    font-size: 20px;
    color: black;
  }
}
