// Here you can add other styles
@font-face {
  font-family: "AlexBrush-Regular";
  src: local("AlexBrush-Regular"),
    url("../fonts/AlexBrush-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Allura-Regular";
  src: local("Allura-Regular"), url("../fonts/Allura-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Arizonia-Regular";
  src: local("Arizonia-Regular"),
    url("../fonts/Arizonia-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "EuphoriaScript-Regular";
  src: local("EuphoriaScript-Regular"),
    url("../fonts/EuphoriaScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MarckScript-Regular";
  src: local("MarckScript-Regular"),
    url("../fonts/MarckScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MeieScript-Regular";
  src: local("MeieScript-Regular"),
    url("../fonts/MeieScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "MonsieurLaDoulaise-Regular";
  src: local("MonsieurLaDoulaise-Regular"),
    url("../fonts/MonsieurLaDoulaise-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rochester-Regular";
  src: local("Rochester-Regular"),
    url("../fonts/Rochester-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RougeScript-Regular";
  src: local("RougeScript-Regular"),
    url("../fonts/RougeScript-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tangerine-Regular";
  src: local("Tangerine-Regular"),
    url("../fonts/Tangerine-Regular.ttf") format("truetype");
}


$theme-colors: (
  "primary": #4638c2,
  "secondary": #4c4f54,
  "success": #45a164,
  "info": #4799eb,
  "warning": #e1a82d,
  "danger": #d16767,
  "light": #20202a,
  "dark": #181924,
);
