@font-face {
  font-family: 'Segoe UI';
  src: url(../public/fonts/segoeui.ttf);
}
@font-face {
  font-family: 'Futura Std';
  src: url(../public/fonts/FuturaStd-Book.otf);
}
@font-face {
  font-family: 'segoeuil';
  src: url(../public/fonts/segoeuil.ttf);
}
@font-face {
  font-family: 'Segoeuib';
  src: url(../public/fonts/segoeuib.ttf);
}
@import "header";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.aut-bg-img {
  background-image: url(../public/assets/img/auth/img-auth.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
  padding-right: 175px;
  color: #fff;
}

.aut-bg-img .auth-content {
  padding: 0px 40px;
}
.auth-wrapper .auth-content {
  position: relative;
  padding: 15px;
  z-index: 5;
}
.auth-wrapper .card {
  margin-bottom: 0;
  padding: 8px;
}
.fade.in {
  opacity: 1 !important;
}

.cpink { color: #F11286; }
.cwhite { color: #FFFFFF; }
.cbg { color: #212529; }
.b6 { font-weight: 600; }
.f14 { font-size: 14px; }
.f13 { font-size: 13px; }
.f12 { font-size: 12px; }
.f11 { font-size: 11px; }
.f10 { font-size: 10px; }
.btn-pink {
  background-color: #F11286 !important;
  color: #fff !important;
  border-color: #F11286 !important;
  border-radius: 0px !important;
}
.seg-ui {
  font-family: "Segoe UI";
}

.fut-std {
  font-family: "Futura Std";
}
.attraction {
  font-family: "Segoeuib";
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1px;
}

.appkeys {
  font-family: "segoeuil";
  font-size: 24px;
}

.pinkline {
  width: 50px;
  height: 2px;
  background: #F11286;
  margin: auto;
}
.header.navbar.navbar-default {
  background: #fff;
}
.page-header-fixed.page-sidebar-fixed .content {
  overflow: inherit;
  margin-left: 0px;
}
.sec-title {
  background: #FCFCFC;
  color: #F11286;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 0px;
}
.header-table {
  background: #E2E2E2;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 500;
}

.row-table {
  background: #fff;
  padding: 10px 0px;
  font-size: 14px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.051);
  margin-top: 10px;
}
.img-pink-border {
  border: 1px solid #F11286;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.profile-pic {
  border: 1px solid #F11286;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}
.accepted {
  display: flex;
  align-items: center;
}
.g-line {
  background-color: #DEE2E6;
  height: 1px;
  display: block;
}
.con-box {
  background-color: #FDFDFD;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.051);
  display: flex;
  min-height: 60px;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
}
.con-box .first-half {
  background-color: #F5F5F5;
  min-width: 60px;
  display: flex;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.con-box .second-half {
  display: flex;
  min-height: 60px;
  align-items: center;
  width: 100%;
}
.con-user {
  min-width: 50px;
  text-align: center;
}
.con-name {
  width: 100%;
  color: #747474;
}
.con-arrow {
  min-width: 24px;
  text-align: center;
}
.con-new {
  min-width: 26px;
  text-align: center;
  font-size: 12px;
  color: #F11286;
}
.con-content {
  box-shadow: 0px 5px 20px rgb(0 0 0 / 5%);
  padding: 5px;
  font-size: 12px;
  min-height: 120px;
}
.con-note {
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 12px;
  width: 100%;
}
.con-note-name {
  color: #747474;
  min-width: 85px;
}
.call-his {
  color: #747474;
  min-width: 70px;
}
.con-icon {
  background-color: #F11286;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.con-note-end {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  font-size: 10px;
}
.con-info {
  max-width: 100%;
  padding-left: 46px;
  padding-right: 20px;
  color: #747474;
}
.jkc-admin-detail-pages .tab-content {
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding: 0 15px;
}
.ad_id_box{
  border: 1px solid #F11286;
    color: gray;
    width: 137px;
    text-align: center;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px;
    font-weight: 600;
    margin-bottom: 8px;
}

.crop-upload div div {
  margin: 0 auto;
}


.first-section{
  display: flex;
  flex-direction: row;
  .tiles-box-section{
    display: flex;
    flex-direction: column;
    width: 40%;
    .number-charts{
      margin-bottom: 10px;
      margin-right: 10px;
      .card-img{
        height: 104px;
      }
    }
    .info-item {
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      position: absolute;
      top: 10px;
      left: 15px;
    }
    .num-amount {
      font-weight: 700;
      font-size: 28px;
      color: #FFFFFF;
      position: absolute;
      top: 57%;
      width: 100%;
      text-align: end;
      padding-right: 1rem;
    }
    .inner-box-section{
      display: flex;
      flex-direction: row;
    }
  }
  .chart-box-section1{
    width: 30%;
    margin-bottom: 12px;
    margin-right: 14px;
    .card{
      height: 330px;
    }
  }
  .chart-box-section2{
    width: 30%;
    margin-bottom: 12px;
    .card{
      height: 330px;
    }
  }
  .table-box-section{
    width: 61%;
  }
}

.bg-round {
  display: inline-block;
  width: 30px;
  height: 12px;
  border-radius: 10px;
  margin-right: 10px;
}
.bg-free {
  background-color: #F11286;
}
.bg-1 {
  background-color: #353535;
}
.bg-3 {
  background-color: #DEE2E6;
}
.bg-6 {
  background-color: #959BA5;
}
.full-donut{
  margin: auto;
  width: 220px;
  position: relative;
}
.half-donut {
  margin: -35px auto;
  width: 300px;
  position: relative;
}
.half-donut-amount {
  position: absolute;
  bottom: 60px;
  text-align: center;
  width: 100%;
  color: #fff;
}
.card-brd-rd {
  border-radius: var(--bs-card-border-radius);
}
.loader-icon {
  color: #fff !important;
  position: absolute;
  top: 0;
  z-index: 9999999999;
  background: #00000075;
  display: block;
  width: 300px;
  height: 100%;
  border-radius: 0%;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  line-height: 300px;
}
.loader-icon-icon {
  color: #fff !important;
  position: absolute;
  top: 0;
  z-index: 9999999999;
  background: #00000075;
  width: 300px;
  height: 300px;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-list {
  position: absolute;
  top: 37px;
  width: 100%;
  background: #fff;
  z-index: 11;
  border: 1px solid #ccc;
}
.member-list .person {
  padding: 5px 10px;
  border-top: 1px solid #ccc;
  cursor: pointer;
}
.w-78 {
  width: 78%;
}
.w-22 {
  width: 22%;
}

.image-upload {
  width: 180px;
  height: 170px;
  cursor: pointer;
  position: relative;
  border: 1px dotted #F11286;
  left: 50%;
  transform: translateX(-50%);
  .drag-text {
    color: #F11286;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin-bottom: 0px;
  }
  .progress-bar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  img{
    max-width: 100%;
    max-height: 100%;
    height: 170px;
  }
}
.clear {
  clear: both;
}