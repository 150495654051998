:scope {
    --nav-tab-border-color : #ccc;
    --nav-tab-active-text-color: #000000;
    --nav-tab-inactive-text-color: gray;
    --nav-tab-font-size: 14px;
    --nav-tab-padding: 5px 10px 5px 10px;
}

.jkc-admin-detail-pages .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--nav-tab-active-text-color) !important;
    background-color: var(--nav-tab-border-color) !important;
    border-color: var(--nav-tab-border-color) !important;
}
.jkc-admin-detail-pages .nav-tabs{
    border-bottom-color: var(--nav-tab-border-color) !important;
}
.jkc-admin-detail-pages .nav-link{
    color: var(--nav-tab-inactive-text-color);
    border-color: var(--nav-tab-border-color);
    font-size: var(--nav-tab-font-size);
    padding: var(--nav-tab-padding);
}
.jkc-admin-detail-pages .nav-link:hover{
    color: var(--nav-tab-inactive-text-color) !important;
    border-color: var(--nav-tab-border-color) !important;
}
.jkc-admin-detail-pages .nav-link.active:hover{
    color: var(--nav-tab-active-text-color) !important;
}

._loading_overlay_overlay{
    position: fixed !important;
    min-height: 100vh !important;
}